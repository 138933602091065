@media (min-width: 640px){
    .is-builder > div {
        display: block !important;
    }
}

//@media (max-width: 40rem){
//    .column{
//        padding-left: 1rem;
//        padding-right: 1rem;
//    }
//}

#_cbhtml > div.is-pop.quickadd.arrow-top.center > div.is-pop-tabs, #_cbhtml > div.is-pop.quickadd.arrow-top.center > div:nth-child(3) > button.add-more.classic,
#_cbhtml > div.is-pop.quickadd.arrow-bottom.center > div.is-pop-tabs, #_cbhtml > div.is-pop.quickadd.arrow-bottom.center > div:nth-child(3) > button.add-more.classic{
    display: none;
}

.contentbuilder{
    p,li,i,b{
        color: #204040;
        a {
            color: #80c040;

            &:hover{
                text-decoration: none;
                border-bottom: 1px solid #80c040;
                transition:all;
            }
        }
    }

    p.text-white{
        color:#fff;
    }

    ul{
        list-style: disc !important;
    }

    ol{
        list-style: decimal !important;
    }

    ul,ol{
        margin-left: 40px !important;
    }

    table{
        color: #204040;
    }
}
