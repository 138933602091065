@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap");
@media (min-width: 640px) {
  .is-builder > div {
    display: block !important;
  }
}
#_cbhtml > div.is-pop.quickadd.arrow-top.center > div.is-pop-tabs, #_cbhtml > div.is-pop.quickadd.arrow-top.center > div:nth-child(3) > button.add-more.classic,
#_cbhtml > div.is-pop.quickadd.arrow-bottom.center > div.is-pop-tabs, #_cbhtml > div.is-pop.quickadd.arrow-bottom.center > div:nth-child(3) > button.add-more.classic {
  display: none;
}

.contentbuilder p, .contentbuilder li, .contentbuilder i, .contentbuilder b {
  color: #204040;
}
.contentbuilder p a, .contentbuilder li a, .contentbuilder i a, .contentbuilder b a {
  color: #80c040;
}
.contentbuilder p a:hover, .contentbuilder li a:hover, .contentbuilder i a:hover, .contentbuilder b a:hover {
  text-decoration: none;
  border-bottom: 1px solid #80c040;
  transition: all;
}
.contentbuilder p.text-white {
  color: #fff;
}
.contentbuilder ul {
  list-style: disc !important;
}
.contentbuilder ol {
  list-style: decimal !important;
}
.contentbuilder ul, .contentbuilder ol {
  margin-left: 40px !important;
}
.contentbuilder table {
  color: #204040;
}